import { render, staticRenderFns } from "./Subject.vue?vue&type=template&id=0747d145&scoped=true"
import script from "./Subject.vue?vue&type=script&lang=js"
export * from "./Subject.vue?vue&type=script&lang=js"
import style0 from "./Subject.vue?vue&type=style&index=0&id=0747d145&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0747d145",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/gitlab-runner/builds/SpMFxzSoQ/0/hero/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0747d145')) {
      api.createRecord('0747d145', component.options)
    } else {
      api.reload('0747d145', component.options)
    }
    module.hot.accept("./Subject.vue?vue&type=template&id=0747d145&scoped=true", function () {
      api.rerender('0747d145', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/employee/Subject.vue"
export default component.exports